import React from "react";
import ah from "../img/ah.png";
import spotify from "../img/spotify.jpg";

export let colors = ["rgb(1, 208, 108)", "rgb(5, 186, 199)"];

/**
 * Generates and returns a font awesome
 * icon for the name provided
 * @param {string} name
 * @param {string} [mainClass] the main icon class (fab, fas, fa...)
 */
export const fetchIcon = (name, mainClass = 'fab') => {
  return <i className={`${mainClass} fa-${name}`} aria-hidden="true" />
};

export const info = {
  firstName: "Adam",
  lastName: "Hanafi",
  initials: "AH",
  positions: ["Software Engineer", "Real estate Investor", "Developer", "Car Enthusiast"],
  selfPortrait: ah,
  gradient: `linear-gradient(135deg, ${colors})`,
  miniBio: [
    ["💼", "Chief Meme Officer at WebNovare"],
    ["☕", "Tech Afficianado"],
    ["🇺🇸", "Based in New York City"],
    ["✉️", "develop@webnovare.com"],
  ],
  socials: [
    ["https://linkedin.com/in/adam-hanafi", fetchIcon('linkedin', 'fa')],
    ["https://x.com/hsquaredcorp", fetchIcon('twitter')],
  ],
  bio:
    "Hello! I'm Adam Hanafi. I'm an enthusiast. I reside in New York City, and I am passionate about developing secure, scalable, and accessible tech. I'm also passionate about photography, classic literature, art, investing, cars, and advocating for gun rights.",
  hobbies: [
    ["🛖", "developing"],
    ["📖", "reading"],
    ["🏎️", "cars"],
    ["✍", "writing"],
    ["🧑‍💻", "networking"],
  ],
  portfolio: [
    {
      title: "Bloomberg Terminal",
      theStory: "Bloomberg Terminal is a computer system that provides access to Bloomberg's investment data, news feeds, messaging, and trade execution services. It allows users to monitor and analyze real-time financial market data and place trades. The Bloomberg Terminal is used by 350,000 of the world's most influential decision makers. It is considered by many to be the gold standard in the financial industry. However, it is also one of the most expensive options, costing upwards of $20,000 annually per person. The Bloomberg Terminal is designed to deliver rich data across market sectors and workflows through one unified system. It connects users to the data, news, analytics, and people they need to make fast, effective decisions. The Bloomberg Terminal is available anytime, from anywhere, with remote and mobile access.",
      liveLink: "#",
      image: "https://bs-uploads.toptal.io/blackfish-uploads/components/seo/content/og_image_file/og_image/1285825/1113-BloombergTerminal-Waldek_Social-14462098a967aff72da3fcdfb5181b3c.png",
      sourceLink: "/blog",
    },
    {
      title: "EPIC medical billing",
      theStory: "Epic is a suite of software applications that healthcare providers use to manage patient records and improve workflows ",
      sourceLink: "/blog",
      image: "https://www.emrsystems.net/wp-content/uploads/2016/09/Epic-EMR-Software-Patient-Summary-Overview.jpg",
    },
    {
      title: "Fortnite",
      theStory: "Fortnite is an online video game and game platform developed by Epic Games and released in 2017. ",
      liveLink: "#",
      sourceLink: "/blog",
    },
    {
      title: "Spotify",
      theStory: "Spotify is a streaming service that provides access to music, podcasts, and other content.",
      image: spotify,
      sourceLink: "/blog",
    },
  ],
  
};
